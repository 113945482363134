import { useState, useEffect } from "react";

/**
 * Custom hook to detect keyboard key states
 * @param {string} key - The key to detect (e.g., "CapsLock", "NumLock", etc.)
 * @returns {boolean} - Whether the key is currently active
 */
export const useKeyboardKeyDetection = (key) => {
  const [isKeyPressed, setIsKeyPressed] = useState(false);

  useEffect(() => {
    const handleKeyEvent = (event) => {
      if ("getModifierState" in event) {
        setIsKeyPressed(event.getModifierState(key));
      }
    };

    // Add event listeners for both keydown and keyup
    window.addEventListener("keydown", handleKeyEvent);
    window.addEventListener("keyup", handleKeyEvent);

    // Cleanup event listeners
    return () => {
      window.removeEventListener("keydown", handleKeyEvent);
      window.removeEventListener("keyup", handleKeyEvent);
    };
  }, [key]); // Add key as dependency

  return isKeyPressed;
};

import React, { useState, useEffect, useCallback, memo } from "react";
import {
   FormGroup,
   Label,
   Input,
   InputGroup,
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
   InputGroupButtonDropdown,
} from "reactstrap";
import { PiCaretDownBold } from "react-icons/pi";

const styleDefault = { backgroundColor: "#3E3D3D" }

/**
 * @typedef {Object} PrefixOption
 * @property {string} value - The prefix value
 * @property {string} [label] - Optional display label for the prefix
 */

/**
 * Input component with a prefix selector dropdown
 * @param {Object} props
 * @param {string} props.id - Input identifier
 * @param {string} [props.label] - Label text
 * @param {string} [props.value] - Input value
 * @param {Function} props.onChange - Change handler function
 * @param {boolean} [props.invalid] - Whether the input is invalid
 * @param {string} [props.placeholder] - Input placeholder text
 * @param {PrefixOption[]} props.prefixOptions - Available prefix options
 * @param {string} [props.defaultPrefix] - Default selected prefix
 * @param {Object} [props.toggleButtonStyle] - Style object for the toggle button
 */
const PrefixSelector = memo(({
   id,
   label = "",
   value,
   onChange,
   invalid = false,
   placeholder = "",
   prefixOptions,
   defaultPrefix,
   toggleButtonStyle,
}) => {
   const [selectedPrefix, setSelectedPrefix] = useState(defaultPrefix);
   const [inputValue, setInputValue] = useState("");
   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

   const toggleDropdown = useCallback(() => {
      setIsDropdownOpen(prev => !prev);
   }, []);

   const handlePrefixChange = useCallback((newPrefix) => {
      setSelectedPrefix(newPrefix);

      if (inputValue) {
         onChange(newPrefix + inputValue);
      }
   }, [inputValue, onChange]);

   const handleInputChange = useCallback(({ target: { value: newValue } }) => {
      setInputValue(newValue);

      if (newValue) {
         onChange(selectedPrefix + newValue);
      } else {
         onChange("");
      }
   }, [selectedPrefix, onChange]);

   useEffect(() => {
      if (value) {
         const matchedPrefix = prefixOptions.find(option =>
            value.startsWith(option.value)
         );
         if (matchedPrefix) {
            setSelectedPrefix(matchedPrefix.value);
            setInputValue(value.slice(matchedPrefix.value.length));
         }
      } else {
         setInputValue("");
      }
   }, [value, prefixOptions]);

   return (
      <FormGroup>
         <Label for={id} hidden={!label}>{label}</Label>
         <InputGroup>
            <InputGroupButtonDropdown
               addonType="prepend"
               isOpen={isDropdownOpen}
               toggle={toggleDropdown}
               style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
            >
               <DropdownToggle style={{ ...styleDefault, ...toggleButtonStyle }} data-testid="prefix-selector-toggle">
                  {selectedPrefix}
                  <PiCaretDownBold size={16} style={{ color: '#FF7200', marginLeft: '.5rem' }} />
               </DropdownToggle>
               <DropdownMenu>
                  {prefixOptions.map((option) => (
                     <DropdownItem
                        key={option.value}
                        onClick={() => handlePrefixChange(option.value)}
                     >
                        {option.label || option.value}
                     </DropdownItem>
                  ))}
               </DropdownMenu>
            </InputGroupButtonDropdown>
            <Input
               id={id}
               placeholder={placeholder}
               value={inputValue}
               invalid={invalid}
               onChange={handleInputChange}
            />
         </InputGroup>
      </FormGroup>
   );
});

PrefixSelector.displayName = 'PrefixSelector';

export default PrefixSelector;

import React from "react";
import { Label, FormGroup, UncontrolledTooltip } from "reactstrap";
import { default as RcSwitch } from "rc-switch";
import classnames from "classnames";
import "rc-switch/assets/index.css";
import "./styles.sass";

/**
 * @typedef {Object} SwitchProps
 * @property {string} id - Unique identifier for the switch
 * @property {string|React.ReactNode} label - Label text or node
 * @property {(checked: boolean) => void} onChange - Change handler function
 * @property {boolean} [floatRight] - Float switch to the right
 * @property {boolean} [floatLeft] - Float switch to the left
 * @property {boolean} [revert] - Reverse the order of label and switch
 * @property {boolean} [bold] - Make label text bold
 * @property {boolean} [checked] - Controlled checked state
 * @property {React.ElementType} [icon] - Icon component to display
 * @property {string} [tooltipId] - ID for the tooltip
 * @property {string} [tooltipText] - Text to display in tooltip
 */

/**
 * Switch component with optional tooltip and icon
 * @param {SwitchProps} props
 * @returns {React.ReactElement}
 */
const Switch = ({
  id,
  label,
  onChange,
  floatRight = false,
  floatLeft = false,
  revert = false,
  bold = false,
  checked,
  icon: Icon = () => <></>,
  tooltipId,
  tooltipText,
  ...rest
}) => {
  const renderLabelContent = () => (
    <>
      <Label
        for={id}
        className={classnames("d-flex h-100 align-items-center m-0", { "font-weight-bold": bold })}
      >
        {label}
      </Label>
      <>
        <Icon id={tooltipId} size={16} style={{ color: "#404243" }} />
        {tooltipId && tooltipText && (
          <UncontrolledTooltip placement="top" target={tooltipId}>
            {tooltipText}
          </UncontrolledTooltip>
        )}
      </>
    </>
  );

  return (
    <FormGroup
      data-testid="switch-form-group"
      className={classnames([
        "d-flex align-items-center",
        {
          "float-right": floatRight,
          "float-left": floatLeft,
        },
      ])}
    >
      <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
        {!revert && renderLabelContent()}
        <RcSwitch id={id} onChange={onChange} checked={checked} {...rest} />
        {revert && renderLabelContent()}
      </div>
    </FormGroup>
  );
};

export default Switch;

import axios from "axios";

export const get = (url, config) => axios.get(url, config);

export const post = (url, params, config) => axios.post(url, params, config);

export const del = (url, params, config) => axios.delete(url, params, config);

export const put = (url, params, config) => axios.put(url, params, config);

export const patch = (url, params) => axios.patch(url, params);

export const download = async(url, filename, type, cancel) => {
    const config = {
        cancelToken: cancel,
        responseType: "arraybuffer",
    };
    const response = await get(url, config);
    save(response, filename, type);
};

export const downloadByLink = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    link.style.display = "none";
    document.body.appendChild(link);

    link.click();
    link.remove();
};

export const save = (response, filename, type) => {
    const url = window.URL.createObjectURL(new Blob([response], { type }));
    downloadByLink(url, filename);
};

export default class Api {
    constructor(baseUrl = "") {
        this.baseUrl = baseUrl;
    }

    fetch = (filter = "", config) => get(`${this.baseUrl}?${filter}`, config);
    get = (id) => get(`${this.baseUrl}/${id}`);
    create = (params, config) => post(this.baseUrl, params, config);
    delete = (id) => del(`${this.baseUrl}/${id}`);
    update = (id, params) => put(`${this.baseUrl}/${id}`, params);
}
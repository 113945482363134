import React, { useState, useCallback } from "react";
import { Input, InputGroup, InputGroupAddon, Label, FormGroup } from "reactstrap";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import classnames from "classnames";
import styles from "./styles.module.sass";

/**
 * Componente de Input que permite gerenciar uma lista de itens
 * @component
 * @param {Object} props
 * @param {string} props.id - Input identifier
 * @param {string} props.label - Label text
 * @param {string} props.placeholder - Input placeholder text
 * @param {Array<Object>} props.items - List of current items
 * @param {Function} props.onItemsChange - Callback when items list changes
 * @param {Function} props.formatItem - Function to format item before adding to list
 * @param {boolean} props.invalid - Whether the input is in invalid state
 * @param {boolean} props.disabled - Whether the input is disabled
 * @param {string} props.buttonAriaLabel - Aria label for the add button
 * @param {string} props.valueKey - Key to use for item value (default: 'value')
 * @param {string} props.idKey - Key to use for item id/uuid (default: 'id')
 */
const InputList = ({
   id,
   label,
   placeholder,
   items = [],
   onItemsChange,
   formatItem = (item) => item,
   invalid = false,
   disabled = false,
   buttonAriaLabel = "Add item",
   requireAtLeastOneItem = false,
   valueKey = "value",
   idKey = "id"
}) => {
   const [inputValue, setInputValue] = useState("");

   const getItemValue = useCallback((item) => {
      return typeof item === 'string' ? item : item[valueKey];
   }, [valueKey]);

   const handleAddItem = useCallback((e) => {
      e.preventDefault();

      if (!inputValue) return;

      const formattedItems = inputValue.split(",").map((item) => {
         const trimmedItem = item.trim();

         const isDuplicate = items.some(item => {
            const existingValue = getItemValue(item);
            return formatItem(existingValue) === formatItem(trimmedItem);
         });

         if (!isDuplicate) {
            return { [valueKey]: trimmedItem };
         }

         return null;
      }).filter(Boolean);

      if (formattedItems.length > 0) {
         onItemsChange([...items, ...formattedItems]);
         setInputValue("");
      }
   }, [inputValue, items, onItemsChange, valueKey, getItemValue]);

   const handleKeyDown = useCallback(
      (e) => {
         if (e.key === "Enter" && inputValue) {
            handleAddItem(e);
         }
      },
      [inputValue, handleAddItem]
   );

   const handleRemoveItem = useCallback((itemValue) => {
      onItemsChange(items.filter(item => getItemValue(item) !== itemValue));
   }, [items, onItemsChange, getItemValue]);

   return (
      <FormGroup autoComplete="off">
         <Label htmlFor={id} hidden={!label}>{label}</Label>
         <InputGroup
            className={classnames(styles.container, {
               [styles["is-invalid"]]: invalid,
               [styles["is-disabled"]]: disabled,
            })}
         >
            <Input
               id={id}
               type="text"
               className={styles.input}
               placeholder={placeholder}
               value={inputValue}
               invalid={invalid}
               disabled={disabled}
               onChange={({ target: { value } }) => setInputValue(value)}
               onKeyDown={handleKeyDown}
            />
            <InputGroupAddon addonType="append" className={styles.buttonContainer}>
               <button
                  className={styles.addButton}
                  onClick={handleAddItem}
                  disabled={!inputValue || disabled}
                  aria-label={buttonAriaLabel}
                  data-testid="add-item-button"
               >
                  <IoIosAddCircleOutline size={24} />
               </button>
            </InputGroupAddon>
         </InputGroup>
         <ul className={classnames(styles.itemsList, { [styles["is-disabled"]]: disabled })}>
            {items.map((item) => {
               const itemValue = getItemValue(item);
               return (
                  <li
                     key={item[idKey] || itemValue}
                     className={
                        classnames(styles.item, {
                           [styles["is-new"]]: !item[idKey],
                           [styles["is-disabled"]]: disabled || (requireAtLeastOneItem && items.length <= 1)
                        })
                     }
                  >
                     <span>{formatItem(itemValue)}</span>
                     <button
                        onClick={() => handleRemoveItem(itemValue)}
                        data-testid="remove-item-button"
                        disabled={disabled || (requireAtLeastOneItem && items.length <= 1)}
                     >
                        <IoClose size={16} />
                     </button>
                  </li>
               );
            })}
         </ul>
      </FormGroup>
   );
};

InputList.displayName = 'InputList';

export default InputList;

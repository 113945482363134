import React, { createContext, useContext, useReducer } from "react";
import reducer, { initialState, actions } from "./reducer";

export const FeedbackModalContext = createContext();

export function StateProvider({ children }) {
    return(
      <FeedbackModalContext.Provider value={useReducer(reducer, initialState)}>
        {children}
      </FeedbackModalContext.Provider>
    );
}

export function useFeedbackModalContext() {
  const [context, dispatch] = useContext(FeedbackModalContext);
  const { value, feedbackAnalysisDTO, feedbackInspectDTO } = context;
  const { others, uncoveredDamage, flaggedAnalyst, helpful, vehiclePartsDTO } = feedbackInspectDTO||{};
  const { hypothesis, useful, notDefined, avoidedInquiry, refusedClaim, releasedClaim } = feedbackAnalysisDTO || {};
  const run = (type, payload) => dispatch({ type, payload });
  return [
    {
      ...context,
      isValid: (
        (others !== null && uncoveredDamage !== null) && (
          (others && flaggedAnalyst !== null) || (
            uncoveredDamage && helpful !== null && (
              !helpful || (helpful && value > 0 && vehiclePartsDTO.length)
            )
          )
        )
      ) || (
        useful !== null && (
          useful === false || (
            useful && hypothesis?.id && avoidedInquiry !== null && refusedClaim !== null && releasedClaim !== null && (
              (avoidedInquiry && (releasedClaim || (refusedClaim && (notDefined || value > 0)))) || 
              (avoidedInquiry === false && refusedClaim === false && releasedClaim === false)
            )
          )
        )        
      )
    },
    {
        setFeedback: feedback => run(actions.CHANGE_FEEDBACK.name, feedback),
        setNote: note => run(actions.CHANGE_NOTE.name, note),
        setUseful: useful => run(actions.CHANGE_USEFUL.name, useful),
        setNotDefined: notDefined => run(actions.CHANGE_NOT_DEFINED.name, notDefined),
        onChangeInquiry: inquiry => run(actions.CHANGE_INQUIRY.name, inquiry),
        setValue: value => run(actions.CHANGE_VALUE.name, value),
        setInquiryValue: inquiryValue => run(actions.CHANGE_INQUIRY_VALUE.name, inquiryValue),
        setBatchUUid: batchUUid => run(actions.CHANGE_BATCH_UUID.name, batchUUid),
        setHypothesis: hypothesis => run(actions.CHANGE_HYPOTESIS.name, hypothesis),
        setHelpful: helpful => run(actions.CHANGE_HELPFUL.name, helpful),
        onChangeDamagedHypothesis: damageHypothesis => run(actions.CHANGE_DAMAGE_HYPOTHESIS.name, damageHypothesis),
        setVehicleParts: vehicleParts => run(actions.CHANGE_VEHICLE_PARTS.name, vehicleParts),
        setFlaggedAnalyst: flaggedAnalyst => run(actions.CHANGE_FLAGGED_ANALYST.name, flaggedAnalyst),
        setInsuranceClaim: insuranceClaim => run(actions.CHANGE_INSURANCE_CLAIM.name, insuranceClaim)
    },
  ];
};



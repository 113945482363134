import React, { useState } from 'react';
import {
    Col,
    Form,
    Button,
    Row,
    TabPane,
    Alert,
} from 'reactstrap';
import { userApi } from 'api';
import { PasswordInput } from 'components';

export default ({ user, loadCallback, close }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const clean = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }
    const onClick = async () => {
        try {
            isOpen && setIsOpen(false);
            isInvalid && setIsInvalid(false);
            loadCallback(true);
            await userApi.changePassword(user, oldPassword, newPassword);
            setIsOpen(true);
            clean();
        } catch (error) {
            setIsInvalid(true);
        } finally {
            loadCallback(false);
        }
    };
    const wrapperClose = () => {
        close();
        clean();
    }

    return (
        <TabPane tabId="2">
            <Form>
                <Alert color="success" {...{ isOpen }} toggle={() => setIsOpen(false)}>
                    Senha alterada com sucesso
                </Alert>
                <Alert color="danger" isOpen={isInvalid} toggle={() => setIsInvalid(false)}>
                    Senha inválida
                </Alert>

                <PasswordInput
                    id="old"
                    value={oldPassword}
                    label="Senha Atual"
                    onChange={({ target: { value } }) => setOldPassword(value)}
                />

                <PasswordInput
                    id="new"
                    value={newPassword}
                    label="Nova Senha"
                    onChange={({ target: { value } }) => setNewPassword(value)}
                    error={!!newPassword && (newPassword.length < 8 || newPassword.length > 16)}
                    success={newPassword || (newPassword.length >= 8 && newPassword.length <= 16)}
                    helperText="A senha deve ter entre 8 e 16 caracteres."
                />

                <PasswordInput
                    id="confirm"
                    value={confirmPassword}
                    label="Confirme Nova Senha"
                    onChange={({ target: { value } }) => setConfirmPassword(value)}
                    error={!!newPassword && newPassword !== confirmPassword}
                    success={!!newPassword && newPassword === confirmPassword}
                    feedbackMessage={!!newPassword && newPassword !== confirmPassword ? "As senhas não correspondem." : ""}
                />
                <Row>
                    <Col>
                        <Button onClick={wrapperClose}>Fechar</Button>
                    </Col>
                    <Col>
                        <Button color="primary" onClick={onClick} className="float-right"
                            disabled={!oldPassword || !newPassword || (newPassword.length < 8 || newPassword.length > 16) || newPassword !== confirmPassword}>
                            Confirmar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </TabPane>
    );
}
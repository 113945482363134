export const defaultTheme = {
    // Primary Colors
    "primary-100": "#FF7200",
    "primary-75": "rgba(255, 114, 0, .75)",
    "primary-50": "rgba(255, 114, 0, .5)",
    "primary-25": "rgba(255, 114, 0, .25)",

    // Neutral Colors
    "neutral-100": "#000000",
    "neutral-90": "#111111",
    "neutral-80": "#505356",
    "neutral-70": "#555555",
    "neutral-60": "#888888",
    "neutral-50": "#AEAFB1",
    "neutral-40": "#C7C7C7",
    "neutral-30": "#CED4DA",
    "neutral-20": "#EBEBEBDE",
    "neutral-10": "#F1F1F1",
    white: "#FFFFFF",

    // Status Colors
    "error-100": "#DC3545",
    "error-700": "#761b25",
    "warning-100": "#f2d16d",
    "warning-700": "#8f6c04",
    "success-100": "#52f162",
    "success-700": "#038c11",
    "info-100": "#5297f1",

    // Brand Colors
    hotlist: "rgb(238, 66, 35)",
    impound: "rgb(255, 230, 41)",

    // Semantic Colors
    link: "#212529",
    "link-hover": "#AEAFB1",

    // Shadow Colors
    "shadow-100": "rgba(0, 0, 0, 0.8)",
    "shadow-75": "rgba(0, 0, 0, 0.6)",
    "shadow-50": "rgba(0, 0, 0, 0.5)",
    "shadow-25": "rgba(0, 0, 0, 0.3)",
};
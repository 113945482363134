import Action, { getReducer } from "../../utils/Action";

export const initialState = Object.freeze({
    plate: "",
    hits: 0,
    value: 0,
    note: "",
    insuranceClaim: "",
    batchUuid: "",
    feedbackAnalysisDTO: {
        hypothesis: null,
        useful: null,
        notDefined: false,
        avoidedInquiry: null,
        refusedClaim: null,
        releasedClaim: null,
        inquiryValue: 0
    },
    feedbackInspectDTO: {
        others: null,
        uncoveredDamage: null,
        flaggedAnalyst: null,
        helpful: null,
        vehiclePartsDTO: []
    }
});

export const actions = Object.freeze({
    CHANGE_FEEDBACK: new Action("CHANGE_FEEDBACK", (_, feedback) => feedback?.id? feedback: initialState),
    CHANGE_NOTE: new Action('CHANGE_NOTE', (state, note) => ({ ...state, note: note.substring(0, 10_000) })),
    CHANGE_USEFUL: new Action('CHANGE_USEFUL', (state, useful) => ({ ...state, feedbackAnalysisDTO: { ...state.feedbackAnalysisDTO, useful }})),
    CHANGE_NOT_DEFINED: new Action('CHANGE_NOT_DEFINED', (state, notDefined) => ({ ...state, feedbackAnalysisDTO: { ...state.feedbackAnalysisDTO, notDefined }})),
    CHANGE_INQUIRY: new Action('CHANGE_INQUIRY', (state, inquiry) => ({ ...state, feedbackAnalysisDTO: { ...state.feedbackAnalysisDTO, ...inquiry }})),
    CHANGE_VALUE: new Action('CHANGE_VALUE', (state, value) => ({ ...state, value })),
    CHANGE_INQUIRY_VALUE: new Action('CHANGE_INQUIRY_VALUE', (state, inquiryValue) => ({ ...state, feedbackAnalysisDTO: { ...state.feedbackAnalysisDTO, inquiryValue }})),
    CHANGE_HYPOTESIS: new Action('CHANGE_HYPOTESIS', (state, hypothesis) => ({ ...state, feedbackAnalysisDTO: { ...state.feedbackAnalysisDTO, hypothesis }})),
    CHANGE_BATCH_UUID: new Action('CHANGE_BATCH_UUID', (state, batchUuid) => ({ ...state, batchUuid })),
    CHANGE_HELPFUL: new Action('CHANGE_HELPFUL', (state, helpful) => ({ ...state, feedbackInspectDTO: { ...state.feedbackInspectDTO, helpful }})),
    CHANGE_DAMAGE_HYPOTHESIS: new Action('CHANGE_DAMAGE_HYPOTHESIS', (state, damageHypothesis) => ({ ...state, feedbackInspectDTO: { ...initialState.feedbackInspectDTO, ...damageHypothesis }})),
    CHANGE_VEHICLE_PARTS: new Action('CHANGE_VEHICLE_PARTS', (state, vehiclePartsDTO) => ({ ...state, feedbackInspectDTO: { ...state.feedbackInspectDTO, vehiclePartsDTO }})),
    CHANGE_FLAGGED_ANALYST: new Action('CHANGE_FLAGGED_ANALYST', (state, flaggedAnalyst) => ({ ...state, feedbackInspectDTO: { ...state.feedbackInspectDTO, flaggedAnalyst }})),
    CHANGE_INSURANCE_CLAIM: new Action('CHANGE_INSURANCE_CLAIM', (state, insuranceClaim) => ({ ...state, insuranceClaim })),
});

export default getReducer(actions);
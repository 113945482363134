import React, { Component } from "react";
import { FaSave, FaAngleLeft, FaAngleRight, FaTimes, FaClock } from "react-icons/fa";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import BounceLoader from "react-spinners/BounceLoader";
import { Else, If, Then, When } from "react-if";
import * as htmlToImage from 'html-to-image';
import classnames from "classnames";
import download from 'downloadjs';
import moment from "moment";
import _ from "lodash";
import Cropper from "../Cropper";
import Editor from "../editor";
import { captureApi } from "api";
import carThumbnail from "assets/car-thumbnail.png";
import styles from "./style.module.sass";

const downloadImage = (img) => {
  const { plate, plateLink } = img;
  htmlToImage.toJpeg(document.getElementById("img-box")).then((dataUrl) => { download(dataUrl, `${plate || plateLink}-${new Date().toLocaleDateString()}.jpeg`) })
};

const imageStyle = {
  verticalAlign: "middle",
  width: "100%",
  fontWeight: "bolder"
};

const createImage = (source, coordinates) =>
  new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
      let coord = coordinates
        .split(";")
        .map((coordinate) => coordinate.split(","));
      const marginWidth = 0.005 * img.width;
      const marginHeight = 0.005 * img.height;
      let xs = [parseInt(coord[0][0]) - marginWidth, parseInt(coord[1][0]) + marginWidth, parseInt(coord[2][0]) + marginWidth, parseInt(coord[3][0]) - marginWidth];
      let ys = [parseInt(coord[0][1]) - marginHeight, parseInt(coord[1][1]) - marginHeight, parseInt(coord[2][1]) + marginHeight, parseInt(coord[3][1]) + marginHeight];
      const canvas = document.createElement("canvas");
      canvas.height = img.height;
      canvas.width = img.width;
      const ctx = canvas.getContext("2d");
      try {
        ctx.drawImage(img, 0, 0);
        ctx.strokeStyle = "lawngreen";
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(xs[0], ys[0]);
        ctx.lineTo(xs[1], ys[1]);
        ctx.lineTo(xs[2], ys[2]);
        ctx.lineTo(xs[3], ys[3]);
        ctx.lineTo(xs[0], ys[0]);
        ctx.stroke();
      } catch (e) {
        reject(e);
        return;
      } finally {
        img.remove();
      }

      canvas.toBlob((blob) => {
        setTimeout(() => canvas.remove(), 100);
        if (!blob) {
          reject("Canvas is empty");
        } else {
          blob.name = `${new Date().getTime()}.jpg`;
          window.URL.revokeObjectURL(source);
          const url = window.URL.createObjectURL(blob);
          resolve(url);
        }
      }, "image/jpeg");
    };
    img.src = source;
  });

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ...(_.omit(props, ['enableFetchOnLast', 'fetchOnLast', 'stopFetchOnLast', 'edit']))
    };
  }

  static defaultProps = {
    style: {},
  };

  static getDerivedStateFromProps({ isMobile, imgs }, { imgs: stateImages, index }) {
    const img = imgs.find(({ filename, coordinates }) => stateImages[index].filename === filename && stateImages[index].coordinates === coordinates);
    if (img?.online) {
      img.source = stateImages[index].source
    }
    return { isMobile, imgs };
  }

  async componentDidMount() {
    document.getElementsByTagName("body")[0].style = "overflow: hidden;";
    window.addEventListener("keydown", this.onKeyPress);
    this.update();
  }

  componentWillUnmount() {
    document.getElementsByTagName("body")[0].style = "";
    window.removeEventListener("keydown", this.onKeyPress);
  }

  onKeyPress = ({ key }) => {
    const { imgs, index, loading } = this.state;
    const { stopFetchOnLast, enableFetchOnLast, fetchOnLast } = this.props;

    // Não processa teclas se estiver carregando
    if (loading) return;

    switch (key) {
      case "Escape": {
        this.props.close();
        break;
      }
      case "ArrowRight": {
        // Verifica se é a última imagem
        if (index === imgs.length - 1) {
          // Se tiver fetchOnLast habilitado, chama a função
          if (!stopFetchOnLast && enableFetchOnLast) {
            fetchOnLast();
          }
          return;
        }
        this.next();
        break;
      }
      case "ArrowLeft": {
        // Verifica se é a primeira imagem
        if (index === 0) return;
        this.prev();
        break;
      }
      default:
        return;
    }
  };

  update = async () => {
    const { imgs, index } = this.state;
    const img = imgs[index];
    let { source } = img;
    const { coordinates, online, expired, storagePath, filename } = img;

    if (!expired && (online && !source)) {
      try {
        source = await captureApi.getImage(
          storagePath + "/" + filename
        );
      } catch ({ response }) {
        if (response?.status === 404) {
          imgs[index].expired = moment(img.dateCaptured).isBefore(moment().subtract(12, 'M'));
        }
      }
    }

    if (source) {
      if (coordinates) {
        imgs[index].source = await createImage(source, coordinates);
      } else {
        imgs[index].source = source;
      }
    }
    const afterUpdate = this.props.afterUpdate || (() => { });
    this.setState({ imgs, loading: false }, afterUpdate);
  };

  prev = () => {
    const { onPrev } = this.props;
    onPrev && onPrev();
    this.setState(
      ({ index }) => ({ loading: true, index: index > 0 ? index - 1 : index }),
      this.update
    );
  };

  next = () => {
    const { onNext } = this.props;
    onNext && onNext();
    this.setState(
      ({ index, imgs }) => ({
        loading: true,
        index: index < imgs.length - 1 ? index + 1 : index,
      }),
      this.update
    );
  };

  render() {
    const { imgs, index, loading, isMobile } = this.state;
    const img = imgs[index];
    const { enableFetchOnLast, fetchOnLast, stopFetchOnLast, edit, style } = this.props;
    return (
      <Row className={styles["popup-image"]} style={{ visibility: "visible" }}>
        {this.props.children}
        <Col hidden={isMobile} md="1">
          <When condition={index}>
            <FaAngleLeft className={styles.arrow} onClick={this.prev} style={{ zIndex: 100 }} />
          </When>
        </Col>
        <FaTimes className={styles.close} onClick={this.props.close} />
        <If condition={loading}>
          <Then>
            <Col xs="12" md="10" className="d-flex justify-content-center align-items-center">
              <span className={styles["loading-icon"]}>
                <BounceLoader size={!isMobile ? 300 : 100} color="white" sizeUnit="px" />
              </span>
            </Col>
          </Then>
          <Else>
            <Col>
              <Editor show={edit && !img?.expired}>
                {
                  filter => (
                    <div id="img-box" className="d-flex w-100 flex-column m-auto h-100">
                      <div className="d-flex w-75 mx-auto" style={{ height: 40, zIndex: 110, float: "top", marginBottom: -40 }}>
                        <span className="mx-auto" style={{ color: "white", fontSize: "1.5rem", textShadow: "0px 0px 4px black" }} onClick={this.close}>
                          {img?.plateLink}
                        </span>
                      </div>
                      <If condition={!img?.expired}>
                        <Then>
                          <Cropper
                            originalSrc={img?.source || carThumbnail}
                            imageStyle={{ ...imageStyle, maxHeight: `calc(85vh -  ${edit && !img?.expired ? 115 : 0}px)`, ...style, filter }}
                          />
                          <div
                            className="d-flex w-100"
                            style={{ height: 40, zIndex: 110, float: "bottom", marginTop: -40, }}
                          >
                            <span
                              className="mx-auto font-weight-bolder"
                              style={{
                                color: "white", fontSize: "1.5rem", textShadow: "0px 0px 4px black"
                              }}>
                              {new Date(img?.dateCaptured).toLocaleString('pt-BR')}
                            </span>
                          </div>
                        </Then>
                        <Else>
                          <div
                            id="expired"
                            className="d-flex w-100"
                            style={{ backgroundColor: '#e6e6e6' }}
                          >
                            <FaClock className="m-auto" size="500" color="white" />
                          </div>
                          <UncontrolledTooltip target="expired">
                            Imagem descartada após 12 meses.
                          </UncontrolledTooltip>
                        </Else>
                      </If>
                    </div>
                  )
                }
              </Editor>
            </Col>
          </Else>
        </If>
        <Col hidden={!isMobile} xs="4" style={{ textAlign: "unset" }}>
          <When condition={index}>
            <FaAngleLeft className={styles.arrow} onClick={this.prev} style={{ zIndex: 100 }} />
          </When>
        </Col>
        <Col className={classnames([{
          [styles["icon-overlay-mobile"]]: isMobile,
          [styles["icon-overlay"]]: !isMobile
        }])} xs="4" md="auto">
          <FaSave style={{ zIndex: 120 }} onClick={() => downloadImage(img)} />
        </Col>
        <Col xs="4" md="1" style={{ textAlign: "right" }}>
          <If condition={index !== imgs.length - 1}>
            <Then>
              <FaAngleRight className={styles.arrow} onClick={this.next} style={{ zIndex: 100 }} />
            </Then>
            <Else>
              <When condition={!stopFetchOnLast && enableFetchOnLast}>
                <FaAngleRight className={styles.arrow} onClick={fetchOnLast} style={{ zIndex: 100 }} />
              </When>
            </Else>
          </If>
        </Col>
      </Row>
    );
  }
}

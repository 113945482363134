export { isValidEmail } from 'docs-validator-br';
export const formatCNPJ = (value = '') => onlyNumbers(value).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
export const formatCPF = (value = '') => onlyNumbers(value).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
export const onlyNumbers = (value = '') => value.replace(/\D/g, "")
export const formatPhone = (value = '') => onlyNumbers(value).substr(0, 11).replace(/^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/, "$1 $2-$3")

export const isValidPhone = (value = '') => new RegExp(/^(\d{2})\D*(\d{5}|\d{4})\D*(\d{4})$/).test(onlyNumbers(value))
export const isValidCNPJ = (value = '') => onlyNumbers(value).length === 14
export const isValidChassi = (chassi = '') =>  {
    // 1 - Possuir o nÃºmero "0" (ZERO) como 1Âº dÃ­gito.
    if (chassi.startsWith("0")) return false;
    // 2 - Possuir espaÃ§o no chassi
    const match = chassi.match(/\w/g)
    chassi = match ? match.join("") : '' // espacoNoChassi
    // 3 - Se, a partir do 4Âº dÃ­gito, houver uma repetiÃ§Ã£o consecutiva, por mais de seis vezes, do mesmo dÃ­gito 
    // (alfabÃ©tico ou numÃ©rico). Exemplos: 9BW11111119452687 e 9BWZZZ5268AAAAAAA.
    if (/^.{4,}([0-9A-Z])\1{5,}/.test(chassi)) return false;
    // 4 - Apresente os caracteres "i", "I", "o", "O", "q", "Q".
    if (/[iIoOqQ]/.test(chassi)) return false;
    // 5 - Os quatro Ãºltimos caracteres devem ser obrigatoriamente numÃ©ricos
    if (!/\d{4}$/.test(chassi)) return false;
    // 6 - Se possuir nÃºmero de dÃ­gitos diferente de 17 (alfanumÃ©ricos). 
    return chassi.length === 17;
}
export const isValidUrl = (value = '') => {
    try {
        new URL(value);
        return true;
    } catch (err) {
        return false;
    }
}


import React from "react";
import { Input, FormFeedback, Label, FormGroup, FormText, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import styles from "./styles.module.sass";

/**
 * Reusable Input component with optional right icon and error/helper text
 * @component
 * @param {string} props.error - Mensagem de erro a ser exibida abaixo do input
 * @param {boolean} props.valid - Indica se o input está válido
 * @param {string} props.feedbackMessage - Mensagem de feedback a ser exibida abaixo do input
 * @param {string} props.className - Classe CSS customizada a ser aplicada ao input
 * @param {string} props.label - Título do input
 * @param {string} props.helperText - Texto de ajuda a ser exibido abaixo do input
 * @param {string} props.autoComplete - Valor do atributo autoComplete do input
 * @param {ReactNode} props.rightIcon - Icone a ser exibido à direita do input
 */
const DefaultInput = ({
  error,
  success,
  feedbackMessage = "",
  className,
  labelClassName,
  label,
  helperText = "",
  autoComplete = "off",
  rightIcon,
  icon: Icon = () => <></>,
  tooltipId,
  tooltipText,
  ...props
}) => {
  return (
    <FormGroup autoComplete="off" className={styles.inputContainer}>
      <Label htmlFor={props.name} className={labelClassName} hidden={!label}>
        {label}
        <>
          <Icon id={tooltipId} size={16} style={{ color: "#404243" }} />
          {tooltipId && tooltipText && (
            <UncontrolledTooltip placement="top" target={tooltipId}>
              {tooltipText}
            </UncontrolledTooltip>
          )}
        </>
      </Label>
      <div className={styles.inputWrapper}>
        <Input
          id={props.name}
          className={classnames(styles.input, className)}
          invalid={!!error}
          valid={!!success}
          autoComplete={autoComplete}
          {...props}
        />
        {rightIcon}

        {!!feedbackMessage &&
          <FormFeedback color={success ? 'success' : error ? 'danger' : 'muted'} data-testid="feedback-message">
            {feedbackMessage}
          </FormFeedback>}
        {!!helperText && !feedbackMessage && <FormText>{helperText}</FormText>}
      </div>
    </FormGroup>
  );
};

export default DefaultInput;
